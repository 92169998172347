import { Loader } from '@googlemaps/js-api-loader'
import { t } from '@woltair/translations-package-fe'
import { browser } from '$app/environment'
import { ADDRESS_TYPE } from '$lib/consts'
import { PUBLIC_GOOGLE_MAP_API_KEY } from '$env/static/public'

export function removeNBSP(string) {
	return string?.replace(/&nbsp;/g, ' ') || ''
}

export function reducer(array = []) {
	const result =
		typeof array === 'object' ? array.reduce((accumulator, curr) => accumulator + curr) : array
	return result
}

export function shiftToBeginning(indices, arr) {
	const brands = arr
	const objectsToShift = []

	for (let i = 0; i < indices.length; i++) {
		const index = indices[i]
		const object = brands.splice(index, 1)[0]
		objectsToShift.push(object)
	}

	for (let i = 0; i < objectsToShift.length; i++) {
		brands.unshift(objectsToShift[i])
	}
	return brands
}
export function pushIfExists(array, value) {
	if (value !== -1) {
		array.push(value)
	}
}

export function sortOrder(arr, order) {
	return arr.sort((a, b) => {
		if (order.indexOf(a.title || a.name) === -1) return 1
		if (order.indexOf(b.title || b.name) === -1) return -1
		if (a === b) return 0
		return order.indexOf(a.title || a.name) - order.indexOf(b.title || b.name)
	})
}

export function getResourceByTag(resources, tagName) {
	return resources?.find((resource) => resource.tags.find((tag) => tag.name === tagName))
}

export function getEmployeeImage(resources) {
	if (resources?.length) {
		const images = resources.filter((res) => {
			const tags = res.tags.map((tag) => tag?.name)
			return tags.includes('employee') && res.contentType.includes('image') && res.url
		})
		if (images.length && images[0]?.url) return images[0].url
	}
	return
}

export function tArray(key: string) {
	const texts: string[] = []

	let i = 1
	while (t(`${key}.${i}`) !== `${key}.${i}`) {
		texts.push(t(`${key}.${i}`) as string)
		i++
	}

	return texts
}

let cachedGeocored

async function geocoder() {
	if (browser && !window?.google) await mapLoader()
	if (!cachedGeocored) cachedGeocored = new window.google.maps.Geocoder()
	return cachedGeocored
}

export function mapLoader() {
	const loader = new Loader({
		apiKey: PUBLIC_GOOGLE_MAP_API_KEY,
		version: 'beta',
		libraries: ['places']
	})

	return loader.load()
}

export async function createBounds() {
	if (browser && !window?.google) await mapLoader()
	return new window.google.maps.LatLngBounds()
}

export async function getLocationFromAddress({ address, placeId, location }) {
	const coder = await geocoder()

	let request = {}
	if (address) request.address = address
	if (placeId) request.placeId = placeId
	if (location) request.location = location

	return coder.geocode(request, (results, status) => {
		if (status === 'OK') {
			return results
		}
	})
}

export function getMyLocation(callback) {
	if (navigator?.geolocation) {
		return navigator.geolocation.getCurrentPosition(callback)
	} else {
		// Browser doesn't support Geolocation
		console.error(`Browser doesn't support Geolocation`)
	}
}

export function buildAddress(address, short) {
	if (!address) return false

	if (
		address?.addressType === ADDRESS_TYPE.FULL_ADDRESS &&
		(address.street === 'unknown' || address.zip === 'unknown')
	) {
		return `NEÚPLNÁ ADRESA - ${address.city}`
	} else if (address?.addressType === ADDRESS_TYPE.FULL_ADDRESS) {
		if (short) return `${address.street}, ${address.city}`
		return `${address.street}, ${address.city}, ${address.zip}`
	}
	if (address?.addressType === ADDRESS_TYPE.COORDINATES) {
		if (short || !address?.city) return `${address.gpsLat}, ${address.gpsLng}`
		return `${address.gpsLat}, ${address.gpsLng} (${address.city})`
	}
}

export function buildGAddress(address) {
	if (!address) return false
	function replaceSpace(string) {
		return string.replace(' ', '+')
	}
	if (address?.addressType === ADDRESS_TYPE.FULL_ADDRESS) {
		return `${replaceSpace(address.street)}%2C+${replaceSpace(address.zip)}%2C+${replaceSpace(
			address.city
		)}`
	}
	if (address?.addressType === ADDRESS_TYPE.COORDINATES) {
		return `${address.gpsLat}%2C+${address.gpsLng}`
	}
}

export function getAddressComponent(addressResponse, keys) {
	const location = addressResponse.address_components.find((component) =>
		component.types.some((type) => keys.includes(type))
	)
	return location?.short_name
}

export async function autocompleteAddress(str, options) {
	const searchString = str.trim()
	if (searchString === '') return []
	if (!window?.google?.maps?.places) await mapLoader()
	const placeAutocompleteService = new google.maps.places.AutocompleteService()
	const { predictions: withoutStreetNumber } = await placeAutocompleteService.getPlacePredictions({
		input: searchString,
		types: ['address'],
		language: options?.lang,
		componentRestrictions: options?.restrictions
	})

	return withoutStreetNumber
}

// countVisiblePages - Used for pagination on blog and expert_advice.
export function countVisiblePages(
	currentPage: number,
	totalPages: number,
	offsetPagination: number = 3
) {
	let start = Math.max(1, currentPage - offsetPagination)
	let end = Math.min(totalPages, currentPage + offsetPagination)
	let pages: (number | string)[] = Array.from({ length: end - start + 1 }, (_, i) => start + i)

	if (start >= offsetPagination) pages.unshift('...')
	if (start + 1 >= offsetPagination) pages.unshift(1)
	if (totalPages - end > 1) pages.push('...')
	if (totalPages !== end) pages.push(totalPages)

	return pages
}

//Cookie management

export function setCookie(name, value, days) {
	let expires = ''
	if (days) {
		const date = new Date()
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
		if (days) {
			expires = '; expires=' + date.toUTCString()
		}
	}
	document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

export function getCookie(name) {
	const nameEQ = name + '='
	const ca = document.cookie.split(';')
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) === ' ') c = c.substring(1, c.length)
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
	}
	return null
}

export function deleteCookie(name) {
	document.cookie = name + '=; Max-Age=-99999999; path=/'
}
